var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageContol formBOX"},[_c('div',{staticClass:"framePage"},[_c('div',{staticClass:"framePage-title"},[_c('span',[_c('em',[_vm._v("当前位置：")]),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("我的资源")]),_c('i',[_vm._v(">")]),_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("我的课程/课件")]),_c('i',[_vm._v(">")]),_c('a',{staticClass:"cur-a",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(_vm.ruleForm.id ? "修改" : "")+"上传课件")])])]),_c('div',{staticClass:"framePage-body"},[_c('div',{staticClass:"framePage-scroll"},[_c('div',{staticClass:"ovy-a"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"10rem"}},[_c('el-form-item',{staticClass:"form-item",attrs:{"label":"课件名称：","prop":"kpointName"}},[_c('el-input',{attrs:{"placeholder":"请输入课件名称","size":"small"},model:{value:(_vm.ruleForm.kpointName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "kpointName", $$v)},expression:"ruleForm.kpointName"}})],1),_c('el-form-item',{staticClass:"form-item",attrs:{"label":"培训类型：","prop":"trainTypeId"}},[_c('el-cascader',{attrs:{"options":_vm.TrainTypeList,"filterable":"","show-all-levels":false,"props":{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                },"size":"small"},model:{value:(_vm.ruleForm.trainTypeId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "trainTypeId", $$v)},expression:"ruleForm.trainTypeId"}})],1),_c('el-form-item',{staticClass:"form-item courseWare",attrs:{"label":"上传课件：","prop":"kpointVideoId"}},[_c('uploadAuth',{attrs:{"btnshow":true,"disabled":_vm.btnshow,"showVideoId":{
                  kpointVideoId: _vm.ruleForm.kpointVideoId,
                  kpointName: _vm.ruleForm.kpointName,
                }},on:{"childFn":_vm.videoIds}})],1),_c('el-form-item',{class:[_vm.ruleForm.id ? 'item-disabled form-item' : 'form-item'],attrs:{"label":"课件封面：","prop":"thumbnail"}},[_c('el-upload',{staticClass:"img-el-upload",attrs:{"on-change":_vm.handleAvatarSuccess2,"before-upload":_vm.$beforeAvatarUpload,"http-request":_vm.$requestMine,"show-file-list":false,"action":"","accept":"image/png, image/gif, image/jpg, image/jpeg"}},[_c('el-image',{staticClass:"imgCenter",attrs:{"src":_vm.ruleForm.thumbnail || require('@/assets/develop.png'),"fit":"contain"}})],1)],1),_c('el-form-item',{staticClass:"form-item form-item-hint",attrs:{"label":" "}},[_c('p',[_vm._v("建议上传尺寸224*160。")])])],1),_c('div',{staticClass:"btnStyle"},[_c('el-button',{staticClass:"bgc-bv",on:{"click":_vm.Cancel}},[_vm._v("取  消")]),_c('el-button',{staticClass:"bgc-bv",on:{"click":function($event){return _vm.formSave('ruleForm')}}},[_vm._v("保  存")])],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }