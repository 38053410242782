<template>
  <div class="pageContol formBOX">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的课程/课件</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ ruleForm.id ? "修改" : "" }}上传课件</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
              class
            >
              <el-form-item
                label="课件名称："
                prop="kpointName"
                class="form-item"
              >
                <el-input
                  v-model="ruleForm.kpointName"
                  placeholder="请输入课件名称"
                  size="small"
                />
              </el-form-item>
              <el-form-item
                label="培训类型："
                prop="trainTypeId"
                class="form-item"
              >
                <el-cascader
                  v-model="ruleForm.trainTypeId"
                  :options="TrainTypeList"
                  filterable
                  :show-all-levels="false"
                  :props="{
                    emitPath: false,
                    value: 'id',
                    label: 'label',
                    children: 'children',
                    checkStrictly: true,
                  }"
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="上传课件："
                prop="kpointVideoId"
                class="form-item courseWare"
              >
                <uploadAuth
                  @childFn="videoIds"
                  :btnshow="true"
                  :disabled="btnshow"
                  :showVideoId="{
                    kpointVideoId: ruleForm.kpointVideoId,
                    kpointName: ruleForm.kpointName,
                  }"
                />
              </el-form-item>
              <el-form-item
                label="课件封面："
                prop="thumbnail"
                :class="[ruleForm.id ? 'item-disabled form-item' : 'form-item']"
              >
                <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item label=" " class="form-item form-item-hint">
                <p>建议上传尺寸224*160。</p>
              </el-form-item>
            </el-form>
            <div class="btnStyle">
               <el-button @click="Cancel" class="bgc-bv"
                  >取&emsp;&emsp;消</el-button
                >
                <el-button class="bgc-bv" @click="formSave('ruleForm')"
                  >保&emsp;&emsp;存</el-button
                >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import uploadAuth from "@/components/uploadAuth";
export default {
  name: "enterpriseInternalTrainingUploadCourseWare",
  components: {
    uploadAuth,
  },
  data() {
    return {
      ruleForm: {
        kpointId: "", //课件Id3
        kpointName: "", //课件名称
        trainTypeId: "",
        thumbnail: "", //封面图Url
        fileKey: "", //封面图key
        kpointVideoId: "", //视频videoId
      },
      rules: {
        kpointName: [
          { required: true, message: "请输入课件名称", trigger: "blur" },
        ],
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        kpointVideoId: [
          { required: true, message: "请上传课件", trigger: "change" },
        ],
      },
      videoId: "",
      TrainTypeList: [],
    };
  },
  computed: {},
  created() {
    this.getTrainTypeArrData();
    if(this.$route.query.stu == 'edit') {
        this.getDetail(this.$route.query.kpointId)
    }
  },
  methods: {
    //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    //上传视频回调
    videoIds(msg) {
      this.videoId = msg;
      this.ruleForm.kpointVideoId = msg;
      if (this.ruleForm.kpointVideoId) {
        this.$refs.ruleForm.validateField("kpointVideoId");
      }
    },
    //上传封面图
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.thumbnail = result.data.fileURL;
          this.ruleForm.fileKey = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    // 保存
    formSave(formName = "ruleForm", type) {
      this.ruleForm.type = type;
      const el = this.$refs[formName];
      el.validate((valid) => {
        if (valid) {
          this.doAjax(formName);
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    doAjax() {
      let params = {
        kpointName: this.ruleForm.kpointName,
        kpointVideoId: this.ruleForm.kpointVideoId,
        kpointLessonNum: 1, //课件学时 写死
        qualificationId: "-1000", //讲师Id 写死
        salesState: "10", //上架状态 写死
      };
      if (this.ruleForm.fileKey) {
        params.kpointLogo = this.ruleForm.fileKey;
      }
      if (this.ruleForm.trainTypeId) {
        params.trainTypeId = this.ruleForm.trainTypeId;
      }
      if (this.ruleForm.kpointId) {
        params.kpointId = this.ruleForm.kpointId;
      }
      this.$post(this.ruleForm.kpointId ?"/biz/courseware/modify":"/biz/courseware/insert", params)
        .then(() => {
          setTimeout(() => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.Cancel("1");
          }, 300);
        })
        .catch(() => {
          return;
        });
    },
    //取消
    Cancel(isRefresh) {
      this.$router.push({
        path: "/web/CustomPrograms/enterpriseInternalTrainingMyCourseCourseWare",
        query: {
          refresh: isRefresh == "1" ? true : false,
          active: "second",
          isAdd: isRefresh == "1" ? true : false,
        },
      });
    },
    //获取详情
    getDetail(kpointId) {
      this.$post("/biz/courseware/kpointView", {
        kpointId
      }).then(result => {
        if (result.status === "0") {
          let res = JSON.stringify(result);
          result = JSON.parse(res);
          this.ruleForm = {
            kpointId: this.$route.query.kpointId,
            kpointName: result.data.kpointName,
            trainTypeId: result.data.trainTypeId,
            kpointLessonNum: result.data.kpointLessonNum,
            kpointVideoId: result.data.kpointVideoId,
            thumbnail: result.data.kpointLogo,
          };
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.btnStyle {
  display: flex;
  justify-content: center;
}
.courseWare {
  display: flex;
  align-items: center;
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
